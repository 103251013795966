import React, { useContext } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { buttonBackground, colors } from '../../constants/styles';
import { device } from '../../constants/mediaQueries';
import { ModalContext } from '../layout';

const ButtonWrap = styled.div`

    text-align: center;
    position: relative;
    margin-top: 10px;

    .btn {
        ${buttonBackground}
        color: ${colors.white};
        border: none;
        border-bottom: 2px solid ${colors.orange3};
        font-weight: bold;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding-left: 4rem;
        padding-right: 1.7rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        position: relative;
        box-shadow: 0 1.2rem 1.5rem 0 ${colors.lightShadowed};
        width: 18rem;
        font-size: 0.7rem;
        margin-bottom: 2.2rem;

        &:focus, &:active {
            border-color: transparent;
        }

        &:before {
            content: '';
            position: absolute;
            left: 1.4rem;
            top: 0.55rem;
            width: 31px;
            height: 30px;
            background: url(${props => props.printUrl}) center center no-repeat;
        }

        &:after {
            @media ${device.deviceS} {
                ${props => {
                    if (props.type === "round"){
                        return `
                            content: '';
                            position: absolute;
                            background: url(${props.arrowRound}) center right no-repeat;
                            width: 294px;
                            height: 74px;
                            left: -144px;
                            top: -35px;
                            z-index: 0;
                        `;
                    }
                }}
            }
        }
    }

    .arrow-text {
        font-weight: 300;
        font-style: italic;

        ${props => {
            if (props.type === "noarrow" || props.type === undefined || props.type === null){
                return 'display: none;'
            } else if (props.type === "normal"){
                return `
                    display: none;
                    position: absolute;
                    left: 50%;
                    bottom: 24px;
                    color: ${colors.white};
                    padding-right: 32px;
                    transform: translate(-420px);
                    font-size: 18px;
                    margin-left: -60px;

                    &:after {
                        position: absolute;
                        content: '';
                        right: 0;
                        bottom: -12px;
                        background: url(${props.arrow}) center right no-repeat;
                        width: 100%;
                        height: 19px;
                    }

                    @media ${device.deviceM} {
                        display: block; 
                    }
                `;
            } else if (props.type === "round") {
                return `
                    display: table;
                    color: ${colors.darkGrey};
                    font-size: 1.1rem;
                    position: relative;
                    background: ${colors.white};
                    z-index: 1;
                    margin: 0 auto;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    padding-bottom: 1.2rem;
                `;
            }
        }}
    }
    

    ${props => {
        if (props.type === "normal" || props.type === undefined || props.type === null || props.type === 'noarrow'){
            return `
                @media ${device.deviceXXS} {
                    .btn {
                        margin-bottom: -1rem;
                        font-size: 0.9rem;
                        width: auto;
                        
                        &:before {
                            top: 0.8rem;
                        }
                    }
                }
            `;
        }
    }}

    

    
`;

const CtaButton = ({ text, arrowType, arrowText, action }) => {
    const {  print, arrowRound, arrow } = useStaticQuery(
        graphql`
          query {
              print: allFile(filter: {name: {eq: "print-sm"}, extension: {eq: "png"}}) {
                  edges {
                    node {
                      publicURL
                    }
                  }
              }
              arrowRound: allFile(filter: {name: {eq: "right-round"}, extension: {eq: "png"}}) {
                edges {
                  node {
                    publicURL
                  }
                }
            } 
            arrow: allFile(filter: {name: {eq: "right"}, extension: {eq: "png"}}) {
                edges {
                  node {
                    publicURL
                  }
                }
            } 
          }
        `
      );

    const modalTools = useContext(ModalContext);

    return (
        <ButtonWrap printUrl={print.edges[0].node.publicURL} type={arrowType} arrowRound={arrowRound.edges[0].node.publicURL} arrow={arrow.edges[0].node.publicURL}>
            {arrowText && <div className="arrow-text">{arrowText}</div>}
            {(action && action.url) && <Link className="btn" to={action.url}>{text}</Link>}
            {(action && action.modal) && <button className="btn" onClick={() => modalTools.handleModalCall(action.modal, action.source)}>{text}</button>}
        </ButtonWrap>
    );
}

export default CtaButton;