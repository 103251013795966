import React from 'react';
import CtaButton from './CtaButton';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { colors, greyGradient } from '../../constants/styles';
import { device } from '../../constants/mediaQueries';

const PhonesWrap = styled.div`
    display: flex;
    position: relative;
    ${greyGradient}
    padding-top: 2.5rem;
    padding-bottom: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    h4 {
        margin-bottom: 0.5rem;
        text-align: center;
        line-height: 2.2rem;
        letter-spacing: 1px;
        color: ${colors.blue};
        font-size: 1.5rem;
        font-weight: 300;
    }

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        list-style: none;
        margin-top: 15px;

        @media ${device.deviceS} {
            flex-direction: row;
        }

        li {
            display: flex;
            padding: 0.5rem;
            align-items: center;

            a {
                display: flex;
                text-decoration: none;
                align-items: center;
                color: ${colors.blue};

                span {
                    border-bottom: 1px solid transparent;
                    letter-spacing: 1.22px;
                    font-size: 1.5rem;
                    font-weight: 700;
                    transition: border-bottom-color .3s;
                    line-height: 2rem;

                    &:hover {
                        border-bottom: 1px solid ${colors.blue};
                    }
                }

                i {
                    display: flex;
                    margin-right: 0.8rem;
                    width: 24px;
                    height: 24px;
                    background-size: 24px 24px;
                }

                .gb {
                    ${props => `background-image: url("${props.icons.gb.edges[0].node.publicURL}");`}
                }

                .us {
                    ${props => `background-image: url("${props.icons.us.edges[0].node.publicURL}");`}
                }
            }
        }
    }
`;
const Phones = () => {
    const flagIcons = useStaticQuery(graphql`
        query {
            gb: allFile(filter: {name: {eq: "gb"}, extension: {eq: "svg"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            us: allFile(filter: {name: {eq: "us"}, extension: {eq: "svg"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            } 
        }
    `);
    return (
        <PhonesWrap icons={flagIcons}>
            <div className="container">
                <h4>Call us for a Free Consultation</h4>
                <ul>
                    <li>
                        <a href="tel:+448000988650">
                            <i className="gb"></i>
                            <span>+44 800 098 86 50 </span>
                        </a>
                    </li>
                    <li>
                        <a href="tel:+16466612998">
                            <i className="us"></i>
                            <span>+1 646 661 29 98</span>
                        </a>
                    </li>
                </ul>
                <div>
                    <CtaButton arrowType="noarrow" text="Get in touch" action={{modal: true, source: 'Phones'}} />
                </div>
            </div>
        </PhonesWrap>
        
    );
}


export default Phones;