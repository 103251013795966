import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { blueGradient, colors } from '../../constants/styles';
import { device } from '../../constants/mediaQueries';

const AdvantagesWrap = styled.section`

    ${blueGradient}
    color: ${colors.white};
    padding-top: 1rem;
    padding-bottom: 1rem;

    .container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        > div {
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0.5rem;
            display: flex;
            align-items: center;
            font-size: 0.95rem;
            line-height: 1rem;

            h3 {
                color: ${colors.white};
                font-size: 1.1rem;;
                font-weight: 700;
                margin: 0;
            }

            .img-box {
                width: 50px;
                margin-right: 0.6rem;
                flex-grow: 0;
                flex-shrink: 0;
            }  
        }

        @media ${device.deviceXS} {
            
            flex-direction: row;
            flex-wrap: wrap;

            > div {
                width: 50%;
            }
        }

        @media ${device.deviceM} {
            > div .img-box {
                width: 80px;
                margin-right: 0.6rem;
                display: flex;
                justify-content: center;
            }  
        }

        @media ${device.deviceL} {
            > div {
                width: 25%;
            }
        }
    }
`;

const Advantages = () => {
    const icons = useStaticQuery(graphql`
        query {
            knowledge: allFile(filter: {name: {eq: "knowledge"}, extension: {eq: "png"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            laptop: allFile(filter: {name: {eq: "laptop"}, extension: {eq: "png"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            hand: allFile(filter: {name: {eq: "hand"}, extension: {eq: "png"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            shield: allFile(filter: {name: {eq: "shield"}, extension: {eq: "png"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            
        }
    `);
    return (
        <AdvantagesWrap>
            <div className="container">
                <div>
                    <div className="img-box">
                        <img src={icons.knowledge.edges[0].node.publicURL} alt="40+ years joint experience" />
                    </div>
                    <div className="text-box">
                        <h3>40+ years joint experience</h3>
                        At least 3 years for each specialist
                    </div>
                </div>
                <div>
                    <div className="img-box">
                        <img src={icons.laptop.edges[0].node.publicURL} alt="14 innovative tools" />
                    </div>
                    <div className="text-box">
                        <h3>14 innovative tools</h3>
                        We develop and use our own tools
                    </div>
                </div>
                <div>
                    <div className="img-box">
                        <img src={icons.hand.edges[0].node.publicURL} alt="48 Google Certifications" />
                    </div>
                    <div className="text-box">
                        <h3>48 Google Certifications</h3>
                        At least 3 certificates for each specialist
                    </div>
                </div>
                <div>
                    <div className="img-box">
                        <img src={icons.shield.edges[0].node.publicURL} alt="We guarantee a positive ROI" />
                    </div>
                    <div className="text-box">
                        <h3>We guarantee a positive ROI</h3>
                        Reach the goal or return 100% payment
                    </div>
                </div>
            </div>
        </AdvantagesWrap>
    );
}

export default Advantages;