import React from 'react';
import styled from 'styled-components';
import { colors, whiteGradient } from '../../constants/styles';
import { useStaticQuery, graphql } from 'gatsby';
import { device } from '../../constants/mediaQueries';

const GeoWrap = styled.section`
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: ${colors.white};

    @media ${device.deviceM}{
        ${whiteGradient}
    }

    .map-wrap {
        text-align: center;
        padding-top: 3rem;
    }

    ul {
        list-style: none;
        color: ${colors.blue};
        letter-spacing: .04em;
        font-weight: 300;
        font-size: 1.2rem;
        text-transform: uppercase;
        text-align: center;
        margin-top: 1rem;
        margin-left: 0;
        margin-bottom: 0;

        @media ${device.deviceM}{
            color: ${colors.white};
            letter-spacing: .06em;
        }

        li {
            padding: 0;
            display: block;
            margin-right: 2rem;
            font-size: 1rem;
            margin-bottom: 1rem;

            span {
                font-weight: 900;
                font-size: 1.2rem;
            }

            @media ${device.deviceS}{
                display: inline-block;

                span {
                    font-size: 1.8rem;
                }
            }

            @media ${device.deviceM}{
                margin-right: 3rem;

                .num1 {
                    font-size: 2rem;
                }
                .num2 {
                    font-size: 2.7rem;
                }
                .num3 {
                    font-size: 3.5rem;
                }
                .num4 {
                    font-size: 3.8rem;
                }
            }
        }
    }
`;

const Geography = () => {
    const Map = useStaticQuery(graphql`
        query {
            allFile(filter: {name: {eq: "map"}, extension: {eq: "png"}}){
                edges {
                    node {
                        publicURL
                    }
                }
            }
        }
    `);
    return (
        <GeoWrap>
            <div className="container">
                <h1 className="huge">Geography of our <strong>clients</strong></h1>
                <div className="map-wrap">
                    <img src={Map.allFile.edges[0].node.publicURL} alt="World Map" />
                </div>
                <ul className="numbers">
                    <li><span className="num1">8</span> countries</li>
                    <li><span className="num2">29</span> cities</li>
                    <li><span className="num3">57</span> clients</li>
                    <li><span className="num4">&gt;100000</span> conversions</li>
                </ul>
            </div>
        </GeoWrap>
    );
}

export default Geography;