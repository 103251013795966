import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/reusable/PageHeader';
import Advantages from '../components/reusable/Advantages';
import Geography from '../components/home/HomeGeography';
import Phones from '../components/reusable/Phones';
import ContactGeoWrap from '../components/reusable/ContactGeoWrap';
import styled from 'styled-components';
import { colors } from '../constants/styles';
import { device } from '../constants/mediaQueries';



const Team = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 3rem;
`;

const Person = styled.div`
    width: 100%;
    display: flex;
    padding: 0.5rem;

    @media ${device.deviceS} {
        width: ${100 / 3}%;
    }

    @media ${device.deviceL} {
        width: ${100 / 4}%;
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
    }

    .name {
        font-weight: 700;
        color: ${colors.darkPurple};
        letter-spacing: .06em;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .job {
        font-weight: 500;
        color: ${colors.black2};
        font-size: 0.9rem;
        letter-spacing: .02em;
        margin-bottom: 0.5rem;
    }

    .person-words {
        height: 4.5rem;
        overflow: hidden;
        font-size: 0.9rem;
        text-justify: none;
    }

    .photo {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        max-width: 100%;
        width: auto;
        height: auto;
        border-radius: 50%;
        border: 15px solid ${colors.lightGrey};
        margin-bottom: 1.65rem;
        flex-shrink: 0;
    }
`;

const PageText = styled.div`
    margin-bottom: 3rem;
`;

const TeamOptimozg = () => {
    return (
        <Layout>
            <SEO title="Team Optimozg" />
            <PageHeader title="Team Optimozg" />
            <StaticQuery 
                query={graphql`
                {
                    allContentfulPeople(sort: { order:ASC, fields: orderNum }) {
                      edges {
                        node {
                          name
                          memo
                          photo {
                            file {
                              url
                            }
                          }
                          jobTitle
                          orderNum
                        }
                      }
                    }
                  }
                `}
                render={({
                    allContentfulPeople : { edges }
                }) => {
                    return (
                        <div className="container">
                            <h1 className="huge">Our Team</h1>
                            <Team>
                                {edges.map(({ node }) => {
                                    return (
                                        <Person key={node.name}>
                                            <div className="content">
                                                <div className="photo">
                                                    <img src={node.photo.file.url} alt={node.name} />
                                                </div>
                                                <p className="name">{node.name}</p>
                                                <p className="job">{node.jobTitle}</p>
                                                <div className="person-words">{node.memo}</div>
                                            </div>
                                            
                                        </Person>
                                    );
                                })}
                            </Team>
                            <PageText>
                                <h3>Are you visiting the page as a potential employee?</h3>
                                <p>We permanently hire if:</p>
                                <ul>
                                    <li>if you've proved and certified 2+ year experience including long term single projects</li>
                                    <li>we like changing rules, if you're an ideas-generator and able to implement an idea or project from the very beginning to the very end by your own</li>
                                    <li>if you are able to take responsibility and manage big budgets</li>
                                    <li>have good communication skills and like to work with clear reporting</li>
                                    <li>we need your expertise, we don't care about:&nbsp;age, religion, disabilites etc.</li>
                                </ul>
                                <p>Don't fit our requirements? Welcome to our 6 months marketing school. Contact us at&nbsp;<a href="mailto:work@optimozg.com" target="_blank" rel="noreferrer">work@optimozg.com</a></p>
                                <h3>Are you a potential partner?</h3>
                                <ul>
                                    <li>We are permanently looking for reliable partners which can help with complementing services, SEO, influence marketing, SMM-posting, Content writing, Website development, mobile app development</li>
                                    <li>If you are a new breakthrough service/tool, we like to test new tools and ready to try your solution</li>
                                </ul>
                            </PageText>
                        </div>
                    );
                }}
            />
            <Advantages />
            <ContactGeoWrap>
                <Geography />
                <Phones />
            </ContactGeoWrap>
        </Layout>
    );
}

export default TeamOptimozg;