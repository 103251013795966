import React from 'react';
import styled from 'styled-components';
import { blueGradient, colors } from '../../constants/styles';
import { device } from '../../constants/mediaQueries';

const HeaderTitle = styled.div`
    ${blueGradient}
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 3rem;

    h1 {
        color: ${colors.white};
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 300;
        letter-spacing: 0;
        text-transform: none;

        @media ${device.deviceS}{
            font-size: 2rem;
        }

        @media ${device.deviceL}{
            font-size: 2.1rem;
        }
    }
`;

const PageHeader = ({ title }) => {
    return (
        <HeaderTitle>
            <div className="container">
                <h1>{title}</h1>
            </div>
        </HeaderTitle>
    );
}

export default PageHeader;