import React from 'react';
import styled from 'styled-components';
import { greenGradient } from '../../constants/styles';
import { useStaticQuery, graphql } from 'gatsby';

const ContactGeoWrapStyle = styled.div`
  ${greenGradient}
  position: relative;
  > * {
    z-index: 2;
    position: relative;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(255,255,255,.3) url(${props => props.backgroundImage}) bottom right no-repeat;
    background-size: auto 612px;
    display: block;
    position: absolute;
    z-index: 1;
  }
`;

const ContactGeoWrap = ({ children }) => {
    const backgroundImage = useStaticQuery(graphql`
        {
            allFile(filter: {name: {eq: "form-bg"}, extension: {eq: "png"}}){
                edges {
                    node {
                        publicURL
                    }
                }
            }
        }
    `); 

    return (
        <ContactGeoWrapStyle backgroundImage={backgroundImage.allFile.edges[0].node.publicURL}>{children}</ContactGeoWrapStyle>
            
    );
}

export default ContactGeoWrap;